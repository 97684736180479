//begin handlebarsjs helpers

// registering and operator on handlebars
Handlebars.registerHelper('ifCondNotAnd', function (v1, v2, options) {
	if (!v1 && !v2) {
		return options.fn(this);
	}
	// use inverse, so the {{else}} can be used on sequnce
	return options.inverse(this);
});

Handlebars.registerHelper('if_or_not_sec', function (v1, v2, options) {
	if (v1 || !v2) {
		return options.fn(this);
	}
	// use inverse, so the {{else}} can be used on sequnce
	return options.inverse(this);
});

Handlebars.registerHelper('if_not_any', function (v1, v2, options) {
	if (!(v1 && v2)) {
		return options.fn(this);
	}
	return options.inverse(this);
});

Handlebars.registerHelper('ifCondNotOr', function (v1, v2, options) {
	if (!v1 || !v2) {
		return options.fn(this);
	}
	return options.inverse(this);
});

/**
  `log` allows you to output the value of variables in the current rendering
  context. `log` also accepts primitive types such as strings or numbers.
  ```handlebars
  {{log "myVariable:" myVariable }}
  ```
  @method log
  @param {Array} params
  @public
*/
Handlebars.registerHelper('log', function () {
	var _console;

	for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
		params[_key] = arguments[_key];
	}

	params.pop();

	(_console = console).log.apply(_console, params);
});

// if the n first arguments are equals the last
Handlebars.registerHelper('if_equals_or', function () {
	//Last argument is the options object.
	var opts = arguments[arguments.length - 1];
	var v2 = arguments[arguments.length - 2];
	for (var i = 0; i < arguments.length - 2; i++) {
		if (arguments[i] == v2) {
			return opts.fn(this);
		}
	}

	// use inverse, so the {{else}} can be used on sequnce
	return opts.inverse(this);
});

// and not for the n first arguments compared to last param
Handlebars.registerHelper('if_not_equals_and', function () {
	//Last argument is the options object.
	var opts = arguments[arguments.length - 1];
	var v2 = arguments[arguments.length - 2];
	var bool = true;
	for (var i = 0; i < arguments.length - 2; i++) {
		bool = bool && arguments[i] !== v2;
	}

	if (bool) {
		return opts.fn(this);
	}

	// use inverse, so the {{else}} can be used on sequnce
	return opts.inverse(this);
});

// if the n first arguments are valid
Handlebars.registerHelper('if_and_exists', function () {
	//Last argument is the options object.
	var opts = arguments[arguments.length - 1];
	var bool = true;
	for (var i = 0; i < arguments.length - 1; i++) {
		bool = bool && arguments[i];
	}

	if (bool) {
		return opts.fn(this);
	}

	// use inverse, so the {{else}} can be used on sequnce
	return opts.inverse(this);
});

// if arg 1 ends with arg 2
//{{#if_ends_with post.url_content '.mp4'}}
Handlebars.registerHelper('if_ends_with', function (a, b, opts) {
	if (a && a.endsWith(b))
		return opts.fn(this);
	else
	// use inverse, so the {{else}} can be used on sequnce
		return opts.inverse(this);
});

// if the n first arguments are valid
Handlebars.registerHelper('if_or_exists', function () {
	//Last argument is the options object.
	var opts = arguments[arguments.length - 1];
	var bool = false;
	for (var i = 0; i < arguments.length - 1; i++) {
		bool = bool || arguments[i];
	}

	if (bool) {
		return opts.fn(this);
	}

	// use inverse, so the {{else}} can be used on sequnce
	return opts.inverse(this);
});

// compares if param a == b
Handlebars.registerHelper('if_equals', function (a, b, opts) {
	if (Object.prototype.toString.call(b) === '[object Array]') {
		for (var i in b) {
			if (a === b[i])
				return opts.fn(this);
		}
	}
	else if (a === b)
		return opts.fn(this);
	else
	// use inverse, so the {{else}} can be used on sequnce
		return opts.inverse(this);
});

// iterate until it reaches its max value param
Handlebars.registerHelper('each_upto_tags_of_posts', function (ary, max, useAllTags, options) {
	// allTags is a class defining when there must be popover
	var allTags = useAllTags ? 'all-tags' : 'all-hashtags';
	if (!ary || ary.length === 0) {
		if (useAllTags)
			return '<span class="metric-block ' + allTags + '" tags="">•••</span>';
		return '';
	}

	var total = ary.length;

	max = ary.length;
	var tmpArr = ary.slice(0, max);
	var maxLen = 34;
	while (tmpArr.join(', ').length > maxLen && max > 0) {
		max--;
		tmpArr = ary.slice(0, max);
	}

	var result = [];
	for (var i = 0; i < max && i < ary.length; ++i)
		result.push(options.fn(ary[i].length > maxLen ? ary[i].substring(0, maxLen) + '...' : ary[i]));
	var endWord = '<span class="metric-block ' + allTags + '" tags="' + ary.join(', ').replaceAll('\"', '&quot;') + '">•••</span>';
	if (!useAllTags && (total - max) <= 0) {
		endWord = '';
	}
	return result.join(' ') + endWord;
});

Handlebars.registerHelper('each_upto', function (ary, max, options) {
	if (!ary || ary.length == 0)
		return options.inverse(this);

	var result = [];
	for (var i = 0; i < max && i < ary.length; ++i)
		result.push(options.fn(ary[i]));
	return result.join('');
});

Handlebars.registerHelper('if_join_title', function (ary) {
	if (ary && ary.length)
		return ary.join(', ');
	else
		return '';
});

// x-if from https://gist.github.com/akhoury/9118682
// for detailed comments and demo, see my SO answer here http://stackoverflow.com/questions/8853396/logical-operator-in-a-handlebars-js-if-conditional/21915381#21915381

/* a helper to execute an IF statement with any expression
 USAGE:
 -- Yes you NEED to properly escape the string literals, or just alternate single and double quotes
 -- to access any global function or property you should use window.functionName() instead of just functionName()
 -- this example assumes you passed this context to your handlebars template( {name: 'Sam', age: '20' } ), notice age is a string, just for so I can demo parseInt later
 <p>
 {{#xif " name == 'Sam' && age === '12' " }}
 BOOM
 {{else}}
 BAMM
 {{/xif}}
 </p>
 */

Handlebars.registerHelper('xif', function (expression, options) {
	return Handlebars.helpers['x'].apply(this, [expression, options]) ? options.fn(this) : options.inverse(this);
});

/* a helper to execute javascript expressions
 USAGE:
 -- Yes you NEED to properly escape the string literals or just alternate single and double quotes
 -- to access any global function or property you should use window.functionName() instead of just functionName(), notice how I had to use window.parseInt() instead of parseInt()
 -- this example assumes you passed this context to your handlebars template( {name: 'Sam', age: '20' } )
 <p>Url: {{x " \"hi\" + name + \", \" + window.location.href + \" <---- this is your href,\" + " your Age is:" + window.parseInt(this.age, 10) "}}</p>
 OUTPUT:
 <p>Url: hi Sam, http://example.com <---- this is your href, your Age is: 20</p>
 */
Handlebars.registerHelper('x', function (expression) {
	var result;
	var context = this;

	// yup, i use 'with' here to expose the context's properties as block variables
	// you don't need to do {{x 'this.age + 2'}}
	// but you can also do {{x 'age + 2'}}
	// HOWEVER including an UNINITIALIZED var in a expression will return undefined as the result.
	// eslint-disable-next-line no-with
	with (context) {
		result = (function () {
			try {
				return eval(expression);
			} catch (e) {
				console.warn('•Expression: {{x \'' + expression + '\'}}\n•JS-Error: ', e, '\n•Context: ', context);
			}
		}).call(context); // to make eval's lexical this=context
	}
	return result;
});

/*
 if you want access upper level scope, this one is slightly different
 the expression is the JOIN of all arguments
 usage: say context data looks like this:

 // data
 {name: 'Sam', age: '20', address: { city: 'yomomaz' } }

 // in template
 // notice how the expression wrap all the string with quotes, and even the variables
 // as they will become strings by the time they hit the helper
 // play with it, you will immediately see the errored expressions and figure it out

 {{#with address}}
 {{z '"hi " + "' ../this.name '" + " you live with " + "' city '"' }}
 {{/with}}
 */
Handlebars.registerHelper('z', function () {
	var options = arguments[arguments.length - 1];
	delete arguments[arguments.length - 1];
	return Handlebars.helpers['x'].apply(this, [Array.prototype.slice.call(arguments, 0).join(''), options]);
});

Handlebars.registerHelper('zif', function () {
	var options = arguments[arguments.length - 1];
	delete arguments[arguments.length - 1];
	return Handlebars.helpers['x'].apply(this, [Array.prototype.slice.call(arguments, 0).join(''), options]) ? options.fn(this) : options.inverse(this);
});

// switch case
Handlebars.registerHelper('switch', function (value, options) {
	this._switch_value_ = value;
	var html = options.fn(this); // Process the body of the switch block
	delete this._switch_value_;
	return html;
});

Handlebars.registerHelper('case', function (value, options) {
	if (value == this._switch_value_) {
		return options.fn(this);
	}
});

Handlebars.registerHelper({
	eq: function (v1, v2) {
		return v1 === v2;
	},
	ne: function (v1, v2) {
		return v1 !== v2;
	},
	lt: function (v1, v2) {
		return v1 < v2;
	},
	gt: function (v1, v2) {
		return v1 > v2;
	},
	lte: function (v1, v2) {
		return v1 <= v2;
	},
	gte: function (v1, v2) {
		return v1 >= v2;
	},
	and: function (v1, v2) {
		return v1 && v2;
	},
	or: function (v1, v2) {
		return v1 || v2;
	},
	not: function (v1) {
		return !v1;
	}
});

Handlebars.registerHelper({
	eqs: function () {
		var boolReturn = true;
		for (var i = 1; i < arguments.length; i++)
			boolReturn = boolReturn && (arguments[i - 1] == arguments[i]);
		return boolReturn;
	},
	ands: function () {
		var boolReturn = true;
		for (var i = 0; i < arguments.length; i++)
			boolReturn = boolReturn && !!arguments[i];
		return boolReturn;
	},
	ors: function () {
		var boolReturn = false;
		for (var i = 1; i < arguments.length; i++)
			boolReturn = boolReturn || !!arguments[i];
		return boolReturn;
	}
});

Handlebars.registerHelper('executeFunction', function (functionString) {
	var result = '';
	try {
		// you can change the context, or merge it with options.data, options.hash
		var context = this;
		var vars = functionString.match(/{{.+}}/g);
		if (vars && vars.length > 0) {
			for (var i = 0; i < vars.length; i++) {
				functionString = functionString.replace(vars[i], context[vars[i].replaceAll('[\{\}]', '')]);
			}
		}
		result = eval(functionString);
	} catch (e) {
		console.log(e);
	}
	return result;
});

// credit: https://github.com/assemble/assemble/issues/228#issuecomment-20853985 e http://stackoverflow.com/questions/30372693/passing-an-array-of-objects-to-a-partial-handlebars-js
Handlebars.registerHelper('getJsonContext', function (data, options) {
	return options.fn(JSON.parse(data));
});

Handlebars.registerHelper('fromNowDate', function fromNowDateHelper(dateTime) {
	return moment(dateTime).fromNow();
});

Handlebars.registerHelper('formatDate', function fromNowDateHelper(dateTime, params) {
	var format = params.hash.format;
	return moment(dateTime).format(format);
});

Handlebars.registerHelper('escapeLinkedinId', function fromNowDateHelper(id) {
	return escapeLinkedinId(id);
});

// PARTIALS
Handlebars.registerPartial('cardpostMetrics', $('#cardpost-metrics-template').html());
Handlebars.registerPartial('cardpostMetricBlock', $('#cardpost-metrics-block').html());
Handlebars.registerPartial('cardpostTags', $('#cardpost-tags-template').html());
Handlebars.registerPartial('cardpostOptions', $('#card-options-part').html());
Handlebars.registerPartial('cardpostTbIcon', $('#card-options-toolbar-icon').html());
Handlebars.registerPartial('citedGroupsPart', $('#citedGroups').html());
Handlebars.registerPartial('spokesmanPartial', $('#spokesman-partial').html());

//filter partial
Handlebars.registerPartial('rangeSliderFilter', $('#range-slider-filter-part').html());
Handlebars.registerPartial('sumoFilterSelector', $('#sumo-filter-selector-part').html());
Handlebars.registerPartial('sumoSingleFilterSelector', $('#sumo-single-filter-selector-part').html());
Handlebars.registerPartial('listOfIconsFilter', $('#list-of-icons-filter-part').html());
Handlebars.registerPartial('listOfIconsFilter2', $('#list-of-icons-filter-part2').html());
Handlebars.registerPartial('select2Filter', $('#select2-filter-selector-part').html());
Handlebars.registerPartial('inputWithTagsFilter', $('#input-with-tags-filter-selector-part').html());
Handlebars.registerPartial('inputWithApartTagsFilter', $('#input-with-apart-tags-filter-part').html());
Handlebars.registerPartial('dateRangeFilter', $('#date-range-filter-template').html());
Handlebars.registerPartial('svgIconChecker', $('#svg-icon-checker').html());
Handlebars.registerPartial('darkPostFilter', $('#darkpost-filter-template').html());

Handlebars.registerPartial('menuPostsOrderby', $('#menu-post-orderby-template').html());
Handlebars.registerPartial('menuPostsOrderbyItem', $('#menu-post-orderby-item-template').html());
Handlebars.registerPartial('metrics-item', $('#cardpost-metrics-item-template').html());

// end handlebars helpers
